import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useTrackEngagementEvent } from '.';

export const useTrackPageViewedEvents = () => {
  const [timeEntered, setTimeEntered] = useState<Date | null>(null);
  const [urlPath, setUrlPath] = useState<string | null>(null);
  const location = useLocation();
  const currentUrlPath = location.pathname;
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const trackEngagementEvent = trackEngagementEventMutation.mutate;
  useEffect(() => {
    if (timeEntered && urlPath) {
      trackEngagementEvent({
        event_name: 'page-view',
        http_path: urlPath,
        event_data: {
          time_entered: timeEntered.toUTCString(),
        },
      });
    }
    return () => {
      if (timeEntered && urlPath) {
        // This will only work when navigating within the platform.
        // Leaving solutions.housecanary.com or closing the browser
        // will not trigger this event
        const timeLeft = new Date();
        trackEngagementEvent({
          event_name: 'page-leave',
          // Need to overwrite because the pathname has changed by the time this event is dispatched
          http_path: urlPath,
          event_data: {
            time_entered: timeEntered.toUTCString(),
            time_left: timeLeft.toUTCString(),
            seconds_on_page: Math.round(
              (timeLeft.getTime() - timeEntered.getTime()) / 1000,
            ),
          },
        });
      }
    };
  }, [timeEntered, urlPath, trackEngagementEvent]);
  useEffect(() => {
    setTimeEntered(new Date());
    setUrlPath(currentUrlPath);
  }, [currentUrlPath]);
};
