import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';

export const useIntercomPageViewEvent = () => {
  const location = useLocation();
  const currentUrlPath = location.pathname;
  const { update } = useIntercom();
  useEffect(() => {
    if (currentUrlPath) {
      // Trigger ping to intercom for SPA page view event
      update({ lastRequestAt: Math.round(new Date().getTime() / 1000) });
    }
  }, [currentUrlPath, update]);
};
