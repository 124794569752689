import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TermsAndPrivacyLinks, useAccount } from '@hcs/authn';
import {
  Anchor,
  AnimatedCheck,
  Button,
  Card,
  EntryPageContainer,
  FormError,
  LayoutBanner,
  LayoutGlobalHeaderChildren,
  LoadingBar,
} from '@hcs/design-system';

import { useResendConfirmationEmail } from '../../hooks';
import { useConfirmUser } from '../../hooks/useConfirmUser';

import styles from './ConfirmUser.module.css';

const CONFIRM_USER_TOKEN_PARAM = 'token';
const CONFIRM_USER_NONCE_PARAM = 'nonce';
const dataHcName = 'confirm-user';
interface ConfirmUserProps {
  header: ReactNode;
  onClickSuccess: VoidFunction;
}
export const ConfirmUser = ({ header, onClickSuccess }: ConfirmUserProps) => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const { data: account, isFetched: accountIsFetched } = useAccount();
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const { mutate: sendConfirmationEmail } = useResendConfirmationEmail();
  const { isSuccess: confirmUserSuccess, mutate: confirmUser } = useConfirmUser(
    {
      onError: (e: Error) => {
        setError(e.message);
      },
    },
  );
  const userIsConfirmed =
    confirmUserSuccess || (account && account.user.confirmed);
  const confirmToken = searchParams.get(CONFIRM_USER_TOKEN_PARAM) || undefined;
  const confirmNonce = searchParams.get(CONFIRM_USER_NONCE_PARAM) || undefined;
  const hasConfirmTokenOrNonce = confirmToken || confirmNonce;
  useEffect(() => {
    if (hasConfirmTokenOrNonce) {
      // only want try to confirm the user if they haven't already been confirmed
      // we also want to wait for account to be loaded to avoid a race condition where useAccount finishes after confirmUser
      // writes to the useAccount cache which results in stale, unconfirmed user data
      if (
        accountIsFetched &&
        (!account || (account && !account.user.confirmed))
      ) {
        confirmUser({ token: confirmToken, nonce: confirmNonce });
      }
    }
    // mutation function is not stable, will change reference but not functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmToken, confirmNonce, accountIsFetched, account]);
  useEffect(() => {
    if (userIsConfirmed) {
      const interval = setInterval(() => {
        setRedirectCountdown((prev) => {
          if (prev === 0) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [userIsConfirmed, onClickSuccess]);

  // the call to onClickSuccess needs to be outside the setRedirectCountdown callback or react complains
  useEffect(() => {
    if (redirectCountdown === 0) {
      onClickSuccess();
    }
  }, [redirectCountdown, onClickSuccess]);

  return (
    <LayoutGlobalHeaderChildren header={header}>
      <EntryPageContainer>
        <LayoutBanner
          title={
            hasConfirmTokenOrNonce
              ? userIsConfirmed
                ? 'Your e-mail address has been confirmed'
                : 'Confirming...'
              : 'Please Confirm Your Email'
          }
          noOutlet
        >
          <Card
            dataHcName={`${dataHcName}-card`}
            maxWidth={800}
            theme={{ Content: styles.SuccessCardContent }}
          >
            {hasConfirmTokenOrNonce ? (
              userIsConfirmed ? (
                <>
                  <div className={styles.Success}>
                    <AnimatedCheck /> Your account has been verified and you can
                    now access the Housecanary Platform. Click the button below
                    to access the HouseCanary platform or we will take you there
                    automatically in {redirectCountdown} seconds.{' '}
                  </div>
                  <Button
                    dataHcName={`${dataHcName}-complete`}
                    onClick={onClickSuccess}
                    className={styles.CompleteButton}
                  >
                    Go to Solutions Platform
                  </Button>
                </>
              ) : error ? (
                <FormError dataHcName={`${dataHcName}-error`} value={error} />
              ) : (
                <LoadingBar dataHcName={`${dataHcName}-loading`} />
              )
            ) : (
              <>
                <p>
                  All users must confirm their email address prior to accessing
                  their HouseCanary account. Please check your inbox and click
                  the confirmation link to continue.
                </p>
                <p>
                  If you need to resend the confirmation email, please{' '}
                  <Anchor
                    dataHcName={`${dataHcName}-resend-confirmation`}
                    onClick={
                      account
                        ? () => sendConfirmationEmail(account?.user.id)
                        : undefined
                    }
                  >
                    click here
                  </Anchor>
                  .
                </p>
              </>
            )}
          </Card>
          <TermsAndPrivacyLinks />
        </LayoutBanner>
      </EntryPageContainer>
    </LayoutGlobalHeaderChildren>
  );
};
