import { useAccount } from '@hcs/authn';
import { combineUseQueryResult } from '@hcs/utils';

import { getOrgSubscriptionType } from '../utils/selfServeInfo.utils';

import { useOrgSelfServeInfo } from './useOrgSelfServeInfo';

export const useOrgSubscriptionType = () => {
  const queryAccount = useAccount();
  const querySsInfo = useOrgSelfServeInfo();
  const combinedQuery = combineUseQueryResult([queryAccount, querySsInfo]);
  return {
    ...combinedQuery,
    data: combinedQuery.isFetched
      ? getOrgSubscriptionType(
          queryAccount.data?.organization.type,
          querySsInfo.data === null
            ? undefined
            : querySsInfo.data?.self_serve_plan,
        )
      : undefined,
  };
};
